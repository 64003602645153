import ContainerMain from "pages/common/ContainerMain"

import user1 from "images/Company/About/Anh-Quang-2.png"
import ceoChinWee from "images/Company/About/Chin_Wee.png"
import pierreCahuzac from "images/Company/About/Pierre_Cahuzac.png"
import ceoDoan_Nguyen_Da_Huong from "images/Company/About/Doan_Nguyen_Da_Huong.png"
import Le_Minh_Duy from "images/Company/About/Le_Minh_Duy.png"
import Pham_Thi_Hieu_Hao from "images/Company/About/Pham_Thi_Hieu_Hao.png"
import Van_Le from "images/Company/About/Van_Le.png"
import Nam_Do from "images/Company/About/Nam_Do.png"
import Nguyen_Le_Hung from "images/Company/About/Nguyen_Le_Hung.png"
import Hua_Thanh_Nha from "images/Company/About/Hua_Thanh_Nha.jpeg"
import Nguyen_Thi_Hong_Nhung from "images/Company/About/Nguyen_Thi_Hong_Nhung.png"
import Tran_Thi_Tuong_Van from "images/Company/About/Tran_Thi_Tuong_Van.png"
import TranMinhDuy from "images/Company/About/tran-minh-duy.png"
import Doan_Thuy_Chi from "images/Company/About/Doan_Thuy_Chi.png"
import Tran_Linh_Phuong_Thanh from "images/Company/About/Tran_Linh_Phuong_Thanh.png"
import Tran_Thiet from "images/Company/About/Tran_Thiet.jpg"
import "./Leadership.scss"
import { useFormatter } from "helpers/i18n"

export default function Leadership() {
  const { __ } = useFormatter()

  const listUser = [
    {
      image: ceoChinWee,
      name: `${__({ defaultMessage: "Chin Wee Tee" })}`,
      position: `${__({ defaultMessage: "Deputy Chief Executive Officer" })}`,
      description: `${__({
        defaultMessage:
          "Chin Wee is highly experienced in information technology management with 20 years of working in various industries, including defence, insurance and supply chain. He focuses on delivering IT and digital solutions that drive business transformation. He is able to work in multi-cultural environment, with stints in Asian countries including Hong Kong, Singapore and Vietnam.<br/><br/> Chin Wee's specialties include IT architecture, software design, IT vendor management, project management, open source platform, AI & data analytics and digitalisation.",
      })}`,
    },
    {
      image: pierreCahuzac,
      name: `${__({ defaultMessage: "Pierre Cahuzac" })}`,
      position: `${__({ defaultMessage: "Chief Operating Officer" })}`,
      description: `${__({
        defaultMessage:
          "Pierre Cahuzac brings over 10 years of senior leadership experience from leading companies. He served as Managing Director BU at POC Pharma, driving product development and market expansion strategies to achieve key milestones. He also held the role of CEO Vietnam at Leflair, boosting revenue growth and operational efficiency, and was SVP, Regional Head of Business Development at LAZADA/Alibaba, overseeing Singapore, Vietnam, and Indonesia, where he spearheaded e-commerce ecosystem expansion and enhanced customer experience.",
      })}`,
    },
    {
      image: Le_Minh_Duy,
      name: `${__({ defaultMessage: "Le Minh Duy" })}`,
      position: `${__({ defaultMessage: "Chief Technology Officer" })}`,
      description: `${__({
        defaultMessage:
          "Duy has over 12 years of experience in Product & System Development in various industries. He placed the initial foundation of OnPoint in 2019. Duy has built the core technology to enable OnPoint business and also Software-as-a-Service product foundation.",
      })}`,
    },
    {
      image: Pham_Thi_Hieu_Hao,
      name: `${__({ defaultMessage: "Pham Thi Hieu Hao" })}`,
      position: `${__({ defaultMessage: "Chief Financial Officer" })}`,
      description: `${__({
        defaultMessage:
          "Pham Thi Hieu Hao, a Master’s graduate in Science in Professional Accountancy from the University of London and an ACCA member, brings extensive finance expertise. She served as SVP – Group Head of Finance & Accounting and Acting CFO at Pomelo Fashion, driving financial strategy and market expansion. Previously, she was Group Financial Controller at a global firm operating in over 30 countries, managing international finance and optimizing costs, and Regional Financial Controller at Easy Taxi (Rocket Internet), overseeing Vietnam and Thailand.",
      })}`,
    },
    {
      image: Nguyen_Le_Hung,
      name: `${__({ defaultMessage: "Nguyen Le Hung" })}`,
      position: `${__({ defaultMessage: "Senior Strategy Director" })}`,
      description: `${__({
        defaultMessage:
          "After a few years in management consulting at E&Y and Dream Incubator, Hung joined OnPoint as one of the first members in late 2016.<br/><br/>Hung spent the last 5 years aggressively building OnPoint from scratch to become number 1 E-commerce enabler in Vietnam and have successfully been fundraised for Series A. Hung is now Head of Strategy, seeking growth opportunities, working with investors for the new capital, and looking for M&A and partners for OnPoint. Prior to this, Hung led teams in Commercial, Supply chain, Customer Service & BI.",
      })}`,
    },
    {
      image: ceoDoan_Nguyen_Da_Huong,
      name: `${__({ defaultMessage: "Doan Nguyen Da Huong" })}`,
      position: `${__({ defaultMessage: "Senior Business Director" })}`,
      description: `${__({
        defaultMessage:
          "Huong started her e-commerce journey from 2013 at Tiki as one of the first builders of Health & Beauty Category. She joined OnPoint mid of 2018 as Group Brand Manager and had been responsible for the commercial success of top-tier clients on e-commerce channels. In Jan 2020, she was promoted to be Business Development Director and responsible for company top-line revenue, P&L, strategy and growth.",
      })}`,
    },
    {
      image: Hua_Thanh_Nha,
      name: `${__({ defaultMessage: "Hua Thi Thanh Nha" })}`,
      position: `${__({ defaultMessage: "Head of Services & Solutions" })}`,
      description: `${__({
        defaultMessage:
          "Nha has more than 12 years of experience in e-commerce. She joined OnPoint from the early days of the company's establishment as a Creative Manager and she built the foundation for the Creative team. She continued in the role of Senior Growth Manager from the end of 2020. In April 2023, she was promoted to Head of Service & Solutions and was responsible for strategy development and growth for OnPoint's new services.In every position, Ms. Nha has helped to systematize work, processes, and operations, creating cohesion and high efficiency in the group.Before joining OnPoint, she held many positions at e-commerce companies such as Vingroup, Webtretho, and Nhommua.com.",
      })}
    `,
    },
    {
      image: Van_Le,
      name: `${__({ defaultMessage: "Le Thi Thanh Van" })}`,
      position: `${__({ defaultMessage: "Head of Influencer Marketing" })}`,
      description: `${__({
        defaultMessage:
          "With over 10 years of experience in media, entertainment, and influencer marketing, Ms. Vân has held leadership roles at top industry companies. Most recently, she served as Head of Influencer Marketing Solutions at AnyMind Group, where she built and developed influencer management solutions for brands.<br /> Previously, she was Head of the Entertainment Business Unit at POPS WORLDWIDE, Business Director at Golden Screen Production, and Director of Business Development at TVHUB, playing a strategic role in market expansion, partner development, and leading successful projects.",
      })}
    `,
    },
    {
      image: Tran_Linh_Phuong_Thanh,
      name: `${__({ defaultMessage: "Tran Linh Phuong Thanh" })}`,
      position: `${__({ defaultMessage: "Human Resource Director" })}`,
      description: `${__({
        defaultMessage:
          'Ms. Thanh brings over 16 years of distinguished leadership in the Human Resources field. She has held senior leadership positions such as Director of People Advisory Services and Head of HR at various organizations and companies across diverse industries, including Banking & Insurance, Manufacturing & Trading, Finance, and Education, with large-scale workforces and rapid growth rates.<br  /><br />At OnPoint, Ms. Thanh will play a pivotal role in formulating and implementing comprehensive HR strategies across all stages of the company’s development. She will also work closely with the HR team to continuously build quality and a conducive work environment for OnPointers, in line with the "Collaborate with People" – one of OnPoint’s values for 2024.',
      })}
    `,
    },
    {
      image: Nguyen_Thi_Hong_Nhung,
      name: `${__({ defaultMessage: "Nguyen Thi Hong Nhung" })}`,
      position: `${__({ defaultMessage: "Associate Commercial Director" })}`,
      description: `${__({
        defaultMessage: `Nhung has in-depth insights in F&B and FMCG industries with 8 years working at SABMiller Vietnam and 3 years at Taisun Vietnam. Nhung joined OnPoint in 2020 as Group Brand Manager and had been responsible for the commercial success of top-tier clients on e-commerce channels.<br/><br/> In 2022, she was promoted to be Associate Commercial Director and responsible for company top-line revenue, P&L, strategy and growth.`,
      })}`,
    },
    {
      image: Tran_Thi_Tuong_Van,
      name: `${__({ defaultMessage: "Tran Thi Tuong Van" })}`,
      position: `${__({ defaultMessage: "Commercial Director" })}`,
      description: `${__({
        defaultMessage:
          "Van is highly experience in retail with 24 years working on various categories such as Fashion, Cosmetic, and Electronics. She used to work on multinational corporations like IPP group, Vietthai International and Lazada. She also reached a C level position at Central group and Lotte group with international brands including Lancome, Shiseido, Ralp Lauren, Giorgio Armani, Aldo Shoes & Accessories, Swarovski Watches & Jewelries as well as other fashion and beauty brands.",
      })}`,
    },
    {
      image: Tran_Thiet,
      name: `${__({ defaultMessage: "Tran Thiet" })}`,
      position: `${__({ defaultMessage: "Commercial Director" })}`,
      description: `${__({
        defaultMessage:
          "Mr. Tran Thiet is a seasoned leader in the e-commerce industry, bringing over 12 years of experience in driving strategic growth and operational excellence across prominent platforms such as Shopee and Lazada.<br /><br />Throughout his career, Mr.Thiet has demonstrated exceptional expertise in category management and strategic business development.As Head of Lifestyle and Commercial Capability at Shopee, he spearheaded initiatives that solidified the Lifestyle category’s market- leading position, underscoring his ability to combine innovative strategies with operational execution to achieve exceptional results.<br /><br />Previously at Lazada, Mr. Thiet exhibited remarkable strategic vision in managing key categories, including Home & Living and Moms, Kids & Babies. His leadership played a pivotal role in enhancing business performance, driving significant revenue growth, and strengthening Lazada’s competitive edge in a dynamic e-commerce landscape.<br /><br />Renowned for his business acumen and forward-thinking approach, Mr. Thiet has consistently delivered sustainable growth and long-term value. His contributions have established him as a distinguished leader in the e-commerce sector, committed to advancing the industry and fostering success in the organizations he serves.",
      })}
    `,
    },
    {
      image: TranMinhDuy,
      name: `${__({ defaultMessage: "Tran Minh Duy" })}`,
      position: `${__({ defaultMessage: "Head of Business Development" })}`,
      description: `${__({
        defaultMessage:
          "Duy has almost 8 years in e-commerce, with experience from FMCG brands, platform to enabler. Besides e-commerce hardcore, he is well-exposed to O-2-O, Social Commerce and Direct to Consumers (Brand.com).<br/><br/>Duy was previously Vice President - LazMall Business Development at Lazada Vietnam, Head of E-commerce at Reckitt, Regional Customer Development and Shopper Marketing Manager at FrieslandCampina AMEA (Asia, Middle East, Africa).",
      })}`,
    },
    {
      image: Nam_Do,
      name: `${__({ defaultMessage: "Do Hoai Nam" })}`,
      position: `${__({ defaultMessage: "Head of Business Transformation" })}`,
      description: `${__({
        defaultMessage:
          "Do Hoai Nam brings over 25 years of experience in digital transformation, technology development, and business strategy. He held senior leadership roles at Google (Industry Head), driving communication strategies and optimizing advertising; DKSH (Regional E-Commerce and Product Director), leading digital transformation in Asia-Pacific; LINE (Commercial Director), restructuring B2B teams for sustainable growth; and Buzzbees (COO), overseeing operations and product development.",
      })}`,
    },
  ]
  const hoverDetail = (name, position, description) => {
    return (
      <>
        <div className="dffcWxOeNW">
          <div className="MQpcLRQujV">{name}</div>
          <div className="WkmrRrGpWz">{position}</div>

          <div
            className="yLQguCYMWv"
            dangerouslySetInnerHTML={{
              __html: `<p>${description}<br/><br/></p>`,
            }}
          ></div>
        </div>
      </>
    )
  }
  return (
    <>
      <div className="nwcjbovxvs">
        <ContainerMain>
          <div className="tsjsriqrqj">
            <div className="leadership-container">
              <div className="dpgzikpmfu section-title">
                {__({
                  defaultMessage: "Leadership",
                })}
              </div>
              <div className="nepxrmvjpl">
                {__({
                  defaultMessage:
                    "Build your online business success with our e-commerce expertise.",
                })}
              </div>
              <div className="wrbbyzmhzv">
                {__({
                  defaultMessage:
                    " Our leadership team have experience in leading the successes of top global and regional companies. We share a passion for driving the next e-commerce evolutions.",
                })}
              </div>
            </div>
          </div>
        </ContainerMain>
        <ContainerMain alignType="right">
          <div className="tsjsriqrqj">
            <div className="ceozyvcdlh">
              <div className="brbcjxqmdo">
                <div className="ycqkfhvkmn">
                  {__({
                    defaultMessage: "Tran Vu Quang",
                  })}
                </div>
                <div className="wtvrdtchxj">
                  {__({
                    defaultMessage: "Founder & CEO",
                  })}
                </div>
                <div className="wejmwdhvxx">
                  {__({
                    defaultMessage:
                      "Quang is the Founder and CEO of OnPoint. Prior to OnPoint, Quang worked as the Chief Commercial Officer at Lazada Vietnam since 2014. Previously, Quang was an Executive Assistant for Vingroup Chairman.",
                  })}
                  <br />
                  <br />
                  {__({
                    defaultMessage:
                      "Quang graduated from Reed College (USA), and started his career at McKinsey as a management consultant, advising various businesses in retail, banking, energy sector across Vietnam, Indonesia, and Singapore.",
                  })}
                </div>
              </div>
              <div className="hbgldslfxb">
                <img src={user1} alt="" className="eowtdhlhvl" />
                <div className="hbgldslfxb-background">
                  <div className="hbgldslfxb-background__impl"></div>
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
      <div className="lppehqasvq">
        <ContainerMain>
          <div className="yxnsfyxays">
            {listUser.map(item => {
              return (
                <div className="jljbxsxkgo">
                  <div className="eszpdechnj">
                    {hoverDetail(item?.name, item.position, item.description)}
                    <img src={item?.image} className="ublinznkzk" alt="" />
                  </div>
                  <div className="ukfgnyzugv">
                    <div className="vynjksrnfx">{item?.name}</div>
                    <div className="hvweqszmiv">{item?.position}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
